
import {
  Component,
  Prop,
  Provide,
  ProvideReactive,
  Vue,
} from 'vue-property-decorator'
import StickyColumnLayoutV2 from '@/layouts/StickyColumnLayoutV2.vue'
import auth from '@/store/modules/auth'
import { isQuoteActive } from '@/utils/quote'
import {
  CheckoutDetailQuote,
  CheckoutDetailResult,
  ComputedPaymentProfile,
  CustomerAccount,
  RefundPolicyEntry,
  SimplifiedPaymentGateway,
  StagePaymentMethod,
  TypeWithId,
} from '@/models/dto'
import quotes from '@/services/quotes'
import CheckoutTripSplitNotice from '@/components/CheckoutTripSplitNotice.vue'
import CheckoutQuoteSummary from '@/components/CheckoutQuoteSummary.vue'
import CheckoutPaymentInformation from '@/components/CheckoutPaymentInformation.vue'
import ReservationCancellationPolicy from '@/components/ReservationCancellationPolicy.vue'
import CheckoutTerms from '@/components/CheckoutTerms.vue'
import CheckoutBookingProtectionGuarantee from '@/components/CheckoutBookingProtectionGuarantee.vue'
import CheckoutValuePropositions from '@/components/CheckoutValuePropositions.vue'
import { FormattedPaymentMethod } from '@/models/FormattedPaymentMethod'
import {
  CompanyId,
  PaymentGatewayTypeKey,
  PaymentMethodTypeKey,
  SplitEvents,
} from '@/utils/enum'
import support from '@/store/modules/support'
import confetti from '@/store/modules/confetti'
import survey from '@/store/modules/survey'
import payment from '@/services/payment'
import CheckoutHeader from '@/components/CheckoutHeader.vue'
import CheckoutSubmitButton from '@/components/CheckoutSubmitButton.vue'
import CheckoutSummaryOverlay from '@/components/CheckoutSummaryOverlay.vue'
import CheckoutTripDetailsAndItinerary from '@/components/CheckoutTripDetailsAndItinerary.vue'
import Chat from '@/components/Chat.vue'
import { currencyFilter } from '@/utils/string'
import { getEarliestDate } from '@/utils/datetime'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import customerAccount from '@/services/customerAccount'
import { QuoteConvertedEventProperties } from '@/models/Split'
import typeService from '@/services/type'
import { StatusCodes } from 'http-status-codes'
import { AxiosError } from 'axios'

@Component({
  components: {
    StickyColumnLayoutV2,
    CheckoutTripSplitNotice,
    CheckoutQuoteSummary,
    CheckoutPaymentInformation,
    ReservationCancellationPolicy,
    CheckoutTerms,
    CheckoutBookingProtectionGuarantee,
    CheckoutValuePropositions,
    CheckoutHeader,
    CheckoutSubmitButton,
    CheckoutSummaryOverlay,
    CheckoutTripDetailsAndItinerary,
    Chat,
  },
})
export default class Checkout extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly disputedCheckout: boolean

  @Provide('isInCheckout') isInCheckout = true

  @ProvideReactive('isSelfServe') isSelfServe = false

  refreshInterval: any = null
  isSubmitting = false
  checkoutDetail: CheckoutDetailResult = null
  bidIds: number[] = null
  quote: CheckoutDetailQuote = null
  formattedPaymentMethodData: FormattedPaymentMethod = null
  paymentProfile: ComputedPaymentProfile = null
  paymentServiceError = ''
  showActionOverlay: boolean = true
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet | null = null
  customerAccount: CustomerAccount | null = null
  paymentMethodTypes: TypeWithId[] = null

  get refundPolicyPercent(): number {
    return this.checkoutDetail?.refundPolicyPercent
  }

  get refundPolicy(): RefundPolicyEntry[] {
    return this.checkoutDetail?.refundPolicy
  }

  get refundPolicyPercentValidUntilTime(): string {
    return this.checkoutDetail?.refundPolicyPercentValidUntilTime
  }

  get quoteId(): number {
    const quoteId = this.$route.params.quoteId
    return quoteId ? parseInt(quoteId) : null
  }

  get isElite(): boolean {
    return !!this.quote?.isElite
  }

  get allowedPaymentMethods(): string[] {
    const paymentMethods = this.quote?.trips?.[0]?.paymentMethods
    if (!paymentMethods) {
      return []
    }
    return paymentMethods
      .filter((method) => method.isAllowed)
      .map((method) => method.paymentMethodType?.key)
  }

  get checkoutPaymentMethods(): StagePaymentMethod[] {
    return this.quote?.trips?.[0]?.stagePaymentMethods?.checkoutPaymentMethods
  }

  get isCreditCardActivePaymentMethod(): boolean {
    return (
      this.formattedPaymentMethodData?.activeMethod ===
      PaymentMethodTypeKey.CreditCard
    )
  }

  get isCheckActivePaymentMethod(): boolean {
    return (
      this.formattedPaymentMethodData?.activeMethod ===
      PaymentMethodTypeKey.Check
    )
  }

  get isAchActivePaymentMethod(): boolean {
    return (
      this.formattedPaymentMethodData?.activeMethod === PaymentMethodTypeKey.ACH
    )
  }

  get storedPaymentSelected(): boolean {
    return (
      this.isCreditCardActivePaymentMethod &&
      !!this.formattedPaymentMethodData?.customerPaymentProfileId
    )
  }

  get hasStoredPaymentProfiles(): boolean {
    return this.isCreditCardActivePaymentMethod && this.storedPaymentSelected
  }

  get isSingleColumn(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get isTripSplit(): boolean {
    return this.quote?.trips.some((trip) => trip.isSplit)
  }

  get earliestStartDate(): string {
    return getEarliestDate(this.quote.trips.map((trip) => trip.startDate))
  }

  get buttonText(): string {
    const baseText = this.$t('checkout.RESERVE_NOW').toString()
    if (!this.isSingleColumn) {
      return baseText
    }

    return `${baseText} for ${currencyFilter(this.checkoutDetail.amountDueNow)}`
  }

  get fullWidth(): boolean {
    return this.$vuetify.breakpoint.width < 480
  }

  get isNewerFleetBid(): boolean {
    return this.checkoutDetail?.isNewerFleet
  }

  get hasTrackingBid(): boolean {
    return this.checkoutDetail?.hasTracking
  }

  checkElementVisibility() {
    if (!this.isSingleColumn) {
      return
    }
    this.showActionOverlay = !this.isOverlayButtonBelowRegularButton()
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkElementVisibility)
    clearInterval(this.refreshInterval)
  }

  async mounted(): Promise<void> {
    window.addEventListener('scroll', this.checkElementVisibility)

    try {
      await this.load()
      if (!this.disputedCheckout) {
        this.refreshInterval = setInterval(async () => {
          if (!this.isSubmitting) {
            await this.load()
          }
        }, 15000)
        this.trackViewCheckout()
      }
    } catch (err) {
      console.error(err)
    }
  }

  async load(): Promise<void> {
    await Promise.all([
      this.getCheckoutDetail(),
      this.getCustomerAccount(),
      this.getEffectiveDefaultValueSet(),
      this.getQuoteTypes(),
    ])
  }

  async getQuoteTypes(): Promise<void> {
    const { data } = await typeService.quoteTypes()
    this.paymentMethodTypes = data?.paymentMethods
  }

  async getCustomerAccount(): Promise<void> {
    const { data } = await customerAccount.byId(
      auth.customerAccount.customerAccountId
    )
    this.customerAccount = data
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    const { data } = await customerAccount.effectiveDefaultValueSet(
      auth.customerAccount.customerAccountId
    )
    this.effectiveDefaultValueSet = data
  }

  async getCheckoutDetail(): Promise<void> {
    const providerId = parseInt(this.$route.params.providerId) || null

    try {
      let response
      if (providerId) {
        response = await quotes.checkoutDetail(
          this.quoteId,
          providerId,
          this.disputedCheckout
        )
      } else {
        response = await quotes.selfServeCheckoutDetail(this.quoteId)
      }
      const { data } = response

      if (!this.disputedCheckout && (!data || !isQuoteActive(data.quote))) {
        this.$router.push({ name: 'quote-index' })
        return
      }
      this.checkoutDetail = data
      this.quote = this.checkoutDetail.quote
      this.bidIds = this.checkoutDetail?.bidIds
      this.isSelfServe = !!this.checkoutDetail.quote?.isSelfServe
    } catch (err) {
      clearInterval(this.refreshInterval)
      this.$router.push({ name: 'quote-index' })
    }
  }

  updateBidIds(bidIds: number[]): void {
    this.bidIds = bidIds
    this.$router.push({
      name: 'checkout-single-bid',
      params: {
        quoteId: this.quoteId.toString(),
      },
    })
  }

  trackViewCheckout(): void {
    this.$ga4Event('view_checkout', {
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
    })
  }

  trackPaymentError(): void {
    this.$ga4Event('payment_error', {
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
    })
  }

  trackQuoteConverted(): void {
    this.$ga4Event('quote_converted', {
      isElite: this.isElite,
      isSelfServe: this.quote?.isSelfServe,
      value: this.checkoutDetail?.totalAmount,
    })

    const quoteProperties: QuoteConvertedEventProperties = {
      isCheapestBidSelected: this.checkoutDetail?.bidOrderIndex === 0,
      indexOfSelectedBid: this.checkoutDetail?.bidOrderIndex,
      isNewerFleetBid: this.isNewerFleetBid,
      isHasTrackingBid: this.hasTrackingBid,
      isFirstConversion:
        !auth.customer.convertedQuoteCount ||
        auth.customer.convertedQuoteCount === 0,
      totalAmount: this.checkoutDetail?.totalAmount,
      isCharterUpChoice: this.checkoutDetail?.isCharterUPChoice,
      isPsychologicalPrice: this.checkoutDetail?.isPsychologicalPrice,
      psychologicalAdjustmentAmount: this.checkoutDetail
        ?.psychologicalAdjustmentAmount,
      psychologicalAdjustmentPercent: this.checkoutDetail
        ?.psychologicalAdjustmentPercent,
    }

    this.$split.track(SplitEvents.QuoteConverted, null, quoteProperties)
  }

  isFormValid(): boolean {
    const paymentForm = this.$refs['payment-information'] as any
    return paymentForm.validate()
  }

  async getDefaultPaymentGateway(): Promise<SimplifiedPaymentGateway> {
    const defaultPaymentGatewayResponse = await payment.getDefaultGateway(
      CompanyId.CharterUP
    )
    const defaultGateway = defaultPaymentGatewayResponse.data.paymentGateways[0]
    return {
      id: defaultGateway.companyPaymentGatewayId,
      key: defaultGateway.paymentGatewayTypeKey,
    }
  }

  async getFinixPaymentGateway(): Promise<SimplifiedPaymentGateway> {
    const defaultPaymentGatewayResponse = await payment.getPaymentGateways()
    const finixGateway = defaultPaymentGatewayResponse.data.paymentGateways
      .filter(
        (paymentGateway) => paymentGateway.companyId === CompanyId.CharterUP
      )
      .filter((paymentGateway) => paymentGateway.checkoutPageId === null)
      .filter(
        (paymentGateway) =>
          paymentGateway.paymentGatewayTypeKey === PaymentGatewayTypeKey.Finix
      )?.[0]
    return {
      id: finixGateway?.companyPaymentGatewayId,
      key: finixGateway?.paymentGatewayTypeKey,
    }
  }

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }

    if (!this.storedPaymentSelected && !this.isFormValid()) {
      return
    }

    let tokenizedPaymentInfo
    let defaultPaymentGateway

    this.paymentServiceError = ''
    this.isSubmitting = true

    try {

      // If we're using Check or ACH, we don't need to tokenize payment info
      if (this.isCheckActivePaymentMethod || this.isAchActivePaymentMethod) {
        tokenizedPaymentInfo = { tokens: [], paymentGateway: null }

        // If we have pre-saved card, we just add the default payment gateway
        // TODO: Remove this; we shouldn't need to send a payment gateway in the request (should use gateway saved to profile)
      } else if (this.storedPaymentSelected) {
        defaultPaymentGateway = await this.getDefaultPaymentGateway()

        // If we're using Finix tokenization, we've already tokenized with the form above and can just get the assocaited payment gateway
      } else if (this.isCreditCardActivePaymentMethod) {
        const finixPaymentGateway = await this.getFinixPaymentGateway()
        if (!finixPaymentGateway || !finixPaymentGateway?.id) {
          this.isSubmitting = false
          return
        }

        try {
          const finixForm = (this.$refs['payment-information'] as any).$refs[
            'credit-card-form'
          ] as any
          const token = await finixForm.getTokenizedFinixInfo()
          tokenizedPaymentInfo = {
            tokens: [token],
            paymentGateway: finixPaymentGateway,
          }
        } catch (error) {
          this.isSubmitting = false
          return
        }

        // Otherwise, assume we're using Auth Net and tokenize with Accept.js
      } else {
        console.warn('Unable to process payment method type')
        this.isSubmitting = false
        return
      }

      const paymentMethodPayload = { ...this.formattedPaymentMethodData }
      delete paymentMethodPayload.customerPaymentProfileId
      delete paymentMethodPayload.saveForFuturePayments

      let convertResponse

      if (this.storedPaymentSelected) {
        convertResponse = await quotes.convert(
          this.quote,
          defaultPaymentGateway,
          paymentMethodPayload,
          this.bidIds,
          this.formattedPaymentMethodData?.customerPaymentProfileId,
          true
        )
      } else {
        convertResponse = await quotes.convert(
          this.quote,
          tokenizedPaymentInfo,
          paymentMethodPayload,
          this.bidIds,
          null,
          !!this.formattedPaymentMethodData?.saveForFuturePayments
        )
      }

      this.handleSubmitSuccess(convertResponse)
    } catch (error) {
      this.handleSubmissionError(error)
    }
  }

  async toSelfServe(): Promise<void> {
    await this.$router.push({
      name: 'self-serve',
      params: { quoteId: this.quoteId.toString() },
    })
  }

  handleSubmitSuccess(convertResponse: any): void {
    this.trackQuoteConverted()
    support.fetchReservations()
    const reservationIds = convertResponse.data.reservationIds
    this.$router.push({
      name: 'reservation-detail',
      params: {
        id: reservationIds?.[0]?.toString(),
        confirm: 'true',
        convertedReservationIds: JSON.stringify(reservationIds),
      },
    })
    confetti.setShow()
    survey.setQuoteId(this.quoteId)
    setTimeout(() => survey.open(), 2000)
  }

  handleSubmissionError(error: AxiosError): void {
    this.isSubmitting = false
    this.trackPaymentError()

    const { status, data } = error.response || {}

    const is409Error = status === StatusCodes.CONFLICT
    const is5xxError = status >= 500 && status < 600
    const shouldObfuscateError = is409Error || is5xxError

    const obfuscatedError =
      'There was an issue processing your payment. Please try again.'
    const errorMessage = data?.message || this.$t('checkout.PAYMENT_ERROR')

    this.paymentServiceError = shouldObfuscateError
      ? obfuscatedError
      : errorMessage
  }

  isOverlayButtonBelowRegularButton(): boolean {
    const checkoutOverlaySubmitButtonRef = this.$refs
      .checkoutOverlaySubmitButton as any
    if (!checkoutOverlaySubmitButtonRef) {
      return false
    }
    const checkoutOverlaySubmitButton = (checkoutOverlaySubmitButtonRef.$el as any).getBoundingClientRect()
    const checkoutSubmitButtonRef = this.$refs.checkoutSubmitButton as any
    const checkoutSubmitButton = (checkoutSubmitButtonRef.$el as any).getBoundingClientRect()

    return checkoutOverlaySubmitButton.top >= checkoutSubmitButton.top
  }
}
